import styled from "styled-components";

interface IStyleProps {
    readonly isDarkTheme?: boolean;
}

export const LogoWrapper = styled.div`
    max-width: 300px;
`;

export const MenuItem = styled.li`
    list-style: none;
    padding: 4px 0;
    height: 24px;
`;

export const MenuLinkStyledOverride = styled.a`
    text-decoration: none;
    font-size: 14px;
    font-weight: 400;
    color: inherit;
    opacity: 50%;

    :hover {
        opacity: 80%;
    }
`;

export const LinkHeading = styled("h1")<IStyleProps>`
    font-size: 14px;
    font-weight: 700;
    padding-bottom: 8px;
    color: inherit;
`;
